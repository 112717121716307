// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@include mat.core();

@import "styles/roboto.scss";
@import "styles/material-icons.scss";

$tsv-navy-blue: (
  50: #eae3f3,
  100: #c9bbe2,
  200: #a68fcf,
  300: #8262bc,
  400: #6841af,
  500: #4c1fa2,
  600: #431b9d,
  700: #351394,
  800: #260d8d,
  900: #000080,
  contrast: (
    50: #f8fde7,
    100: #edf8c4,
    200: #e1f59d,
    300: #d5f075,
    400: #cceb56,
    500: #c4e834,
    600: #b8d52d,
    700: #a8be23,
    800: #99a719,
    900: #808000,
  ),
);

$tsv-blue-munsell: (
  50: #e0f5fc,
  100: #b2e5f6,
  200: #80d5f0,
  300: #4ec4e6,
  400: #2bb9de,
  500: #19add6,
  600: #129ec2,
  700: #048aa8,
  800: #00778f,
  900: #005563,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);

$tsv-twillight-lavender: (
  50: #fbe8ef,
  100: #f7c6d7,
  200: #f3a3bd,
  300: #ef83a4,
  400: #eb6e90,
  500: #e8617e,
  600: #d75c79,
  700: #bf5572,
  800: #a9506b,
  900: #80475e,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);

$tsv-granny-smith-apple: (
  50: #ecfbec,
  100: #d1f5d0,
  200: #b3efb2,
  300: #95e993,
  400: #7ee17c,
  500: #6bda65,
  600: #60c95a,
  700: #53b54e,
  800: #49a344,
  900: #348331,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tsv-travemuende-primary: mat.define-palette($tsv-blue-munsell);
$tsv-travemuende-accent: mat.define-palette($tsv-granny-smith-apple);

// The warn palette is optional (defaults to red).
$tsv-travemuende-warn: mat.define-palette($tsv-twillight-lavender);

$tsv-travemuende-typography: mat.define-typography-config(
  $font-family: "Roboto, Helvetica Neue, sans-serif",
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tsv-travemuende-theme: mat.define-light-theme(
  (
    color: (
      primary: $tsv-travemuende-primary,
      accent: $tsv-travemuende-accent,
      warn: $tsv-travemuende-warn,
    ),
    typography: $tsv-travemuende-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tsv-travemuende-theme);

/* You can add global styles to this file, and also import other style files */
$navy-blue: #000080ff;
$blue-munsell: #048ba8ff;
$blue-munsell-700: #048ba8ff;
$blue-munsell-800: #00778f;
$alice-blue: #e8f1f2ff;
$granny-smith-apple: #b3efb2ff;
$twilight-lavender: #80475eff;

body {
  margin: 0;
  font-family: Arial, "Helvetica Neue", sans-serif;
  // background: linear-gradient(to bottom right, $blue-munsell-800 0, $blue-munsell-700 100%);
  background: whitesmoke;

  .all-spacer {
    margin: auto;
  }

  .fab {
    z-index: 99;
    position: fixed;
    bottom: 4.5rem;
    right: 2rem;
    color: white;
    background-color: #00189a;
  }

  a {
    text-decoration: none;
    color: #000000;
    letter-spacing: normal !important;
  }

  button {
    letter-spacing: normal !important;
  }

  .formfield-with-hint {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .formfield {
      width: 100%;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.2rem;
  }

  // If the screen is less than 600px hide the content.
  @media only screen and (max-width: 450px) {
    .hide-xs {
      display: none !important;
    }
  }

  @media only screen and (max-width: 449px) {
    .show-from-450 {
      display: none !important;
    }
  }

  @media only screen and (min-width: 450px) {
    .hide-from-450 {
      display: none !important;
    }
  }

  // If the screen is less than 600px hide the content.
  @media only screen and (max-width: 600px) {
    .hide-sm {
      display: none !important;
    }
  }

  // If the screen is less than 900px hide the content.
  @media only screen and (max-width: 900px) {
    .hide-md {
      display: none !important;
    }
  }

  // If the screen width is more than 600px hide the content.
  @media only screen and (min-width: 601px) {
    .hide-lg {
      display: none !important;
    }
  }

  // TODO: check if this is used and remove it as it is the same as hide-md
  // If the screen is less than 900px hide the content.
  @media only screen and (min-width: 901px) {
    .show-md {
      display: none !important;
    }
  }
}

// TODO: check if these can be put somewhere else
:root {
  --swiper-navigation-color: whitesmoke;
  --swiper-pagination-color: whitesmoke;
}
